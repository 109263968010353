import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PageHeading from '../components/PageHeading';
import ItemCard from '../components/ItemCard';
// import Sort from '../components/Sort';
// import { swapIcon } from '../svgs';
import GridCard from '../components/GridCard';
import Input from '../components/Input';
import Pagination from '../components/Pagination';
import { PageSpinner } from '../../../staticComponents/spinner';
import { connect } from 'react-redux';
import { fetchCatalogueListing as fetchListingService } from '../../../service';
import { catalogueSteps } from './index';
import { currencyMapper } from '../../../utils/entity_mapping';
import { useExchangeContext } from '../wrapper';
import NotFound from '../components/NotFound';
import { LETSDRESSUP } from '../defaultValues';

const allProdCategoryId = 'all_prods'; // creating this to not send id when user clicks all products because all products means no category ID to be sent

const Catalogue = ({
  variants,
  fetchListing,
  orderInfo,
  listingType,
  onClick,
  onGoBack,
  exchangeConfig,
  categoryId,
  onCategorySelect,
  tracking,
  user
}) => {
  const { state } = useExchangeContext();
  const { categories, products } = variants;
  const { config, udfKeys } = tracking.theme;
  const catalogue =
    listingType === catalogueSteps.CATEGORIES ? categories : products;
  const { pageInfo } = catalogue ?? {};

  const {
    catalogueConfig: {
      category_info,
      product_collection_only,
      percentageDiscount
    },
    forward_order_discount
  } = exchangeConfig;
  const [search, setSearch] = useState('');
  const firstRender = React.useRef(true);

  const selectedProduct = orderInfo.request[state.editProductId] ?? {};
  const fetchCatalogue = fetchListing(listingType, selectedProduct);

  const filteredCategoryInfo =
    category_info?.filter(item =>
      item.title.toLowerCase().includes(search.toLowerCase())
    ) ?? [];
  // check to show search bar and all Products categories
  const showSearch =
    (!product_collection_only &&
      (!category_info || category_info.length === 0)) ||
    (category_info.length >= 1 && udfKeys?.enableCategorySearch);

  const isNoResultOrError =
    catalogue?.nodes?.length === 0 ||
    (category_info?.length > 0 &&
      filteredCategoryInfo.length === 0 &&
      listingType === catalogueSteps.CATEGORIES) ||
    variants.isError;

  const searchParams =
    listingType === catalogueSteps.CATEGORIES ||
    categoryId === allProdCategoryId
      ? {}
      : {
          shopify_category_id: categoryId
        };

  useEffect(() => {
    if (
      !(category_info?.length > 0 && listingType === catalogueSteps.CATEGORIES)
    ) {
      fetchCatalogue(searchParams);
    }
    if (
      product_collection_only &&
      orderInfo?.products?.[0]?.iteminfoextended__product_id
    ) {
      fetchCatalogue({
        product_id: orderInfo.products[0].iteminfoextended__product_id
      });
    }
  }, []);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      if (firstRender.current) {
        firstRender.current = false;
        return;
      }
      onSearch();
    }, 2000);

    return () => clearTimeout(searchTimeout);
  }, [search]);

  const onSearch = clear => {
    const query = { ...searchParams };
    if (search && !clear) {
      query[
        listingType === catalogueSteps.CATEGORIES
          ? 'category_name'
          : 'product_name'
      ] = search;
      delete query.shopify_category_id;
    }
    if (
      !(category_info?.length > 0 && listingType === catalogueSteps.CATEGORIES)
    ) {
      fetchCatalogue(query);
    }
  };

  const onNext = () => {
    const query = { ...searchParams, end_cursor: pageInfo.endCursor };
    if (search) {
      query[
        listingType === catalogueSteps.CATEGORIES
          ? 'category_name'
          : 'product_name'
      ] = search;
      delete query.shopify_category_id;
    }

    fetchCatalogue(query);
  };

  const onPrevious = () => {
    const query = { ...searchParams, start_cursor: pageInfo.startCursor };
    if (search) {
      query[
        listingType === catalogueSteps.CATEGORIES
          ? 'category_name'
          : 'product_name'
      ] = search;
      delete query.shopify_category_id;
    }
    fetchCatalogue(query);
  };

  const cancelSearch = () => {
    firstRender.current = true;
    // setCancelToken(true);
  };
  const clearSearch = () => {
    setSearch('');
    cancelSearch();
    onSearch(true);
  };

  const getIsCardDisabled = item => {
    if (user.subdomain === LETSDRESSUP) {
      return item.hide;
    }

    return item.totalInventory <= 0 || item.hide;
  };

  const renderBadge = variant => {
    const difference =
      Number(variant.priceRangeV2.minVariantPrice.amount) -
      Number(
        selectedProduct.price -
          (percentageDiscount || forward_order_discount
            ? 0
            : selectedProduct.price_breakup?.discount ?? 0)
      );

    if (variant.totalInventory <= 0 && user.subdomain !== LETSDRESSUP)
      return (
        <div className="rounded-pill text-red-900 bd-1 bg-white pd-5 pd-top-0 pd-btm-0 d-flex items-center">
          Out of stock
        </div>
      );

    if (difference === 0) return null;

    return (
      <div
        className={`rounded-pill ${
          difference > 0 ? 'text-red-900' : 'text-green'
        }  bd-1 bg-white pd-5 pd-top-0 pd-btm-0 d-flex items-center`}
      >
        {orderInfo.currency ?? currencyMapper(config.country_code) + ' '}
        {Math.abs(difference).toFixed(2)}
        <img
          src={
            difference > 0
              ? 'images/arrow_up_red.svg'
              : '/images/arrow_down_green.svg'
          }
          alt="arrow-up"
        />
      </div>
    );
  };

  return (
    <div className="d-flex flex-col ht-100">
      <PageHeading
        heading={
          listingType === catalogueSteps.CATEGORIES
            ? 'Select Exchange Item Category'
            : 'Select Exchange Item'
        }
        goback={onGoBack}
      />
      <ItemCard
        title={selectedProduct.description}
        price={`${currencyMapper(orderInfo.currency ?? config.country_code)} ${
          selectedProduct.price
        }`}
        paidPrice={
          selectedProduct.price_breakup?.discount &&
          !percentageDiscount &&
          !forward_order_discount
            ? `${currencyMapper(
                orderInfo.currency ?? config.country_code
              )} ${selectedProduct.price -
                selectedProduct.price_breakup?.discount}`
            : null
        }
        qty={selectedProduct.quantity}
        src={selectedProduct.images}
      />
      {/* {!product_collection_only && (
        <label className="input-switch mrg-top-20 xs-pd-left-10 xs-pd-right-10">
          <span className="mrg-right-5 fs-14">
            Enabled search across all products
          </span>
          <input
            name="globalSearch"
            onChange={() => setIsGlobalSearch(!isGlobalSearch)}
            checked={isGlobalSearch}
            type="checkbox"
          />
        </label>
      )} */}

      {showSearch && (
        <div className="mrg-top-20 mrg-btm-20 xs-pd-left-10 xs-pd-right-10">
          <Input
            onKeyDown={e => {
              if (e.key === 'Enter') {
                cancelSearch();
                onSearch();
              }
            }}
            value={search}
            onChange={e => setSearch(e.target.value)}
            id={'search-input'}
            // icon={<span className="icon-search" />}
            className={'search-input'}
            placeholder={
              'Search' +
              (catalogueSteps.CATEGORIES === listingType
                ? ' across categories'
                : ' across all products')
            }
            rightAction={
              <div className="d-flex align-items-center">
                {search && (
                  <span
                    role="button"
                    tabIndex={0}
                    className="icon-close small d-flex items-center mrg-right-5"
                    onClick={clearSearch}
                    onKeyDown={clearSearch}
                  />
                )}
                <button
                  className="ant-button"
                  onClick={e => {
                    e.stopPropagation();
                    cancelSearch();
                    onSearch();
                  }}
                >
                  <span className="icon-search" />
                </button>
              </div>
            }
          />

          {/* <Sort
              label="Sort by"
              icon={swapIcon}
              id={'sort-by-select'}
              options={[
                {
                  label: 'A-Z',
                  value: 'alpha'
                },
                {
                  label: 'low to high',
                  value: 'price'
                }
              ]}
            /> */}
        </div>
      )}

      {!variants.isFetching && isNoResultOrError && (
        <div className="d-flex flex-col mrg-top-10 ht-100 items-center justify-center">
          <NotFound
            heading={
              variants.isError
                ? 'Something wrong happened!'
                : 'No results found'
            }
            description={
              variants.isError
                ? 'please try again later.'
                : 'Please search something else'
            }
          />

          {!variants.isError && showSearch && (
            <button
              className="ant-button ghost mrg-top-20"
              onClick={() => {
                onCategorySelect(allProdCategoryId);
                clearSearch();
              }}
            >
              Show All Products
            </button>
          )}
        </div>
      )}
      {variants.isFetching && <PageSpinner />}
      {!isNoResultOrError && !variants.isFetching && (
        <>
          <div className="ht-100 mrg-top-10 overflow-auto xs-pd-left-10 xs-pd-right-10 d-flex flex-col justify-between">
            <div className="grid-container  col-2 space-20">
              {listingType === catalogueSteps.CATEGORIES && showSearch && (
                <GridCard
                  id={allProdCategoryId}
                  onClick={onClick}
                  key={'all_products'}
                  label={'All Products'}
                  isTextIcon
                />
              )}
              {!(
                category_info?.length > 0 &&
                listingType === catalogueSteps.CATEGORIES &&
                !product_collection_only
              )
                ? catalogue?.nodes?.map(item => (
                    <GridCard
                      id={item.id}
                      onClick={onClick}
                      disabled={getIsCardDisabled(item)}
                      key={item.id}
                      label={item.title}
                      price={
                        listingType === catalogueSteps.PRODUCTS
                          ? item.priceRangeV2.minVariantPrice.amount
                          : null
                      }
                      src={
                        item[
                          listingType === catalogueSteps.CATEGORIES
                            ? 'image'
                            : 'featuredImage'
                        ]?.url
                      }
                      badge={
                        listingType === catalogueSteps.PRODUCTS
                          ? renderBadge(item)
                          : null
                      }
                    />
                  ))
                : filteredCategoryInfo.map(item => (
                    <GridCard
                      key={item.category_id}
                      id={item.category_id}
                      onClick={onClick}
                      label={item.title}
                      src={item.images}
                    />
                  ))}
            </div>
            {!(
              category_info?.length > 0 &&
              listingType === catalogueSteps.CATEGORIES
            ) && (
              <Pagination
                className={'mrg-top-10 mrg-btm-10'}
                hasNext={pageInfo?.hasNextPage}
                hasPrevious={pageInfo?.hasPreviousPage}
                onNext={onNext}
                onPrevious={onPrevious}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

Catalogue.propTypes = {
  variants: PropTypes.any,
  fetchListing: PropTypes.any,
  listingType: PropTypes.any,
  orderInfo: PropTypes.any,
  onClick: PropTypes.any,
  onGoBack: PropTypes.any,
  exchangeConfig: PropTypes.any,
  categoryId: PropTypes.any,
  user: PropTypes.any,
  onCategorySelect: PropTypes.any,
  tracking: PropTypes.any
};

const mapStateToProps = state => ({
  variants: state.variants,
  orderInfo: state.orderInfo,
  exchangeConfig: state.exchangeConfig,
  user: state.user,
  tracking: state.tracking
});
const mapDispatchToProps = dispatch => ({
  fetchListing: (listingType, selectedProd) => query =>
    dispatch(fetchListingService(query, listingType, selectedProd))
});

export default connect(mapStateToProps, mapDispatchToProps)(Catalogue);

import React from 'react';
import PropTypes from 'prop-types';

const ItemCard = ({ title, price, qty, src, variant, paidPrice }) => {
  return (
    <div className="d-flex xs-pd-left-10 xs-pd-right-10">
      <img
        width={100}
        height={100}
        src={src ?? '/images/img-placeholder.svg'}
        alt="product-img"
        className="mrg-right-10 object-fit-cover min-w-100 img-rounded"
      />
      <div className="d-flex flex-col justify-between pd-5">
        <div>
          <p>{title}</p>
          <p className="fw-600">
            {paidPrice ? (
              <>
                <span className="line-through mx-1">{price}</span>
                {paidPrice}
              </>
            ) : (
              price
            )}
          </p>
        </div>
        <div>
          <span>Qty: {qty}</span>
          {variant ? (
            <>
              <span className="text-gray mrg-left-5 mrg-right-5">|</span>
              <span>Size: {variant}</span>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

ItemCard.propTypes = {
  title: PropTypes.string,
  price: PropTypes.any,
  qty: PropTypes.number,
  src: PropTypes.string,
  variant: PropTypes.string,
  paidPrice: PropTypes.string
};

export default ItemCard;
